import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./components/Login";
import Header from "./components/Header";
import Toggle from "./components/Toggle";

function App() {
	return (
		<div className="container-fluid">
			<Header />
			<BrowserRouter>
				<Routes>
					<Route exact path="/" element={<Login />} />
					<Route exact path="/toggle" element={<Toggle />} />
				</Routes>
			</BrowserRouter>
		</div>
	);
}

export default App;
