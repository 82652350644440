import { db } from "../firebase";
import {
	collection,
	addDoc,
	updateDoc,
	doc,
	Timestamp,
	onSnapshot,
	query,
	orderBy,
	where
} from "firebase/firestore";
import moment from "moment";
const COLLECTION_NAME = "nodes";

class Utils {
	static updateAccountStatus = async (id, status) => {
		return new Promise(async (resolve, reject) => {
			const docRef = doc(db, COLLECTION_NAME, id);
			let result = await updateDoc(docRef, {
				status: status,
				modifiedTime: moment().format()
			});
			resolve(result);
		});
	};

	static getByAccountIdAndPin = async (accountId, pin) => {
		return new Promise((resolve, reject) => {
			const taskColRef = query(
				collection(db, COLLECTION_NAME),
				where("accountId", "==", accountId)
			);
			onSnapshot(taskColRef, snapshot => {
				let t = snapshot.docs.map(doc => ({
					id: doc.id,
					...doc.data()
				}));
				if (
					t &&
					t.length === 1 &&
					parseInt(t[0].accountPin) === parseInt(pin)
				) {
					resolve(t[0]);
				} else {
					resolve(null);
				}
			});
		});
	};
}

export default Utils;
