import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import Utils from "../utils/utils";
import Constants from "../utils/constants";
import Loading from "./Loading";

function Login() {
	const [redirectTo, setRedirectTo] = useState(null);
	const [accountId, setAccountId] = useState("");
	const [pin, setPin] = useState("");
	const [errorMessage, setErrorMessage] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	const handleSubmit = e => {
		e.preventDefault();
		if (!accountId) {
			setErrorMessage("Please provide Account ID.");
			return;
		}
		if (!pin) {
			setErrorMessage("Please provide Pin.");
			return;
		}

		setIsLoading(true);
		Utils.getByAccountIdAndPin(accountId, pin).then(r => {
			if (!r) {
				setErrorMessage("No matching account found.");
				setIsLoading(false);
				return;
			}
			localStorage.setItem(
				Constants.LOCAL_STORAGE_KEY,
				JSON.stringify(r)
			);
			setIsLoading(false);
			setRedirectTo(`/toggle`);
		});
	};

	return (
		<>
			{redirectTo && <Navigate replace to={redirectTo} />}
			{isLoading && <Loading />}
			<div className="row mt-30">
				<div className="col">
					<form onSubmit={handleSubmit}>
						<div className="form-group mb-3">
							<label>Account ID</label>
							<input
								type="text"
								className="form-control"
								placeholder="Account ID"
								required
								value={accountId}
								onChange={e => setAccountId(e.target.value)}
							/>
						</div>
						<div className="form-group mb-3">
							<label>Pin</label>
							<input
								type="text"
								className="form-control"
								placeholder="Pin"
								required
								value={pin}
								onChange={e => setPin(e.target.value)}
							/>
						</div>
						{errorMessage && (
							<div className="form-group mb-3">
								<label className="text-danger">
									{errorMessage}
								</label>
							</div>
						)}
					</form>
				</div>
			</div>
			<div className="row mt-30 text-center">
				<div className="col text-center">
					<button
						type="submit"
						className="btn btn-info w-100"
						onClick={handleSubmit}
					>
						Submit
					</button>
				</div>
			</div>
		</>
	);
}

export default Login;
