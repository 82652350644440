import React from "react";
import logo from "../media/logo.png";

function Header() {
	return (
		<div className="row header">
			<div className="col-4 text-center">
				<img src={logo} className="img-fluid img-logo" />
			</div>
			<div className="col-8">
				<div className="display-5">Sarrix Fire Sprinkler Control</div>
			</div>
		</div>
	);
}

export default Header;
