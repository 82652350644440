import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import Utils from "../utils/utils";
import Constants from "../utils/constants";
import Loading from "./Loading";

function Toggle() {
	const [redirectTo, setRedirectTo] = useState(null);
	const [account, setAccount] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		let r = JSON.parse(localStorage.getItem(Constants.LOCAL_STORAGE_KEY));
		if (r) {
			setAccount(r);
		} else {
			window.location.href = "/";
		}
	}, []);

	const reload = () => {
		setIsLoading(true);
		let d = JSON.parse(localStorage.getItem(Constants.LOCAL_STORAGE_KEY));
		if (!d) return;

		setIsLoading(true);
		Utils.getByAccountIdAndPin(account.accountId, account.accountPin).then(
			r => {
				localStorage.setItem(
					Constants.LOCAL_STORAGE_KEY,
					JSON.stringify(r)
				);
				setAccount(r);
				setIsLoading(false);
			}
		);
	};

	const handleToggle = e => {
		e.preventDefault();
		if (!account) return;

		setIsLoading(true);
		let newStatus = account.status === "off" ? "on" : "off";
		Utils.updateAccountStatus(account.id, newStatus).then(r => {
			setIsLoading(false);
			reload();
		});
	};

	if (account)
		return (
			<>
				{redirectTo && <Navigate push to={redirectTo} />}
				{isLoading && <Loading />}
				<div className="row mt-30">
					<div className="col">
						<div className="tbl">
							<table>
								<tbody>
									<tr>
										<td
											className="text-start"
											style={{ width: 150 }}
										>
											Account ID
										</td>
										<td className="text-start">
											{account.accountId}
										</td>
									</tr>
									<tr>
										<td className="text-start">
											Location
										</td>
										<td className="text-start">
											{account.customLocation}
										</td>
									</tr>
									<tr>
										<td className="text-start">
											Custom URL
										</td>
										<td className="text-start">
											<a
												href={account.customURL}
												target="_blank"
											>
												{account.customURL}
											</a>
										</td>
									</tr>
									<tr>
										<td className="text-start">Status</td>
										<td className="text-start">
											<span
												style={{
													textTransform: "uppercase"
												}}
											>
												{account.status}
											</span>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<div className="row mt-30 text-center">
					<div className="col">
						<div>
							<i
								className="fa-solid fa-power-off fa-4x mt-10"
								style={{
									color:
										account.status === "off"
											? "red"
											: "green"
								}}
								onClick={handleToggle}
							></i>
							<div style={{ marginTop: 10 }}>
								{account.status === "off"
									? "Turn on"
									: "Turn off"}
							</div>
						</div>
					</div>
				</div>
			</>
		);
}

export default Toggle;
